<template>
  <el-container class="mx-3">
    <el-row justify="center" class="w-100">
      <div class="mt-3 mb-3 d-none">
        <button @click="handleClickGetAuth">google classroom GetAuth</button>
        <button @click="handleClickSignIn">
          button for test - google classroom SignIn
        </button>
        <button @click="handleClickLogin">
          handleClickLogin
        </button>
      </div>

      <div>
        <button id="authorize-button" style="display: none;">Authorize</button>
        <button id="signout-button" style="display: none;">Sign Out</button>

        <div id="content"></div>
      </div>

      <el-col :span="24">
        <el-card>
          <template #header>
            <div class="font-weight-light" :class="{ 'text-right': isRtl() }">
              {{ localization("Complete your profile") }}
            </div>
          </template>
          <el-tabs
            success
            v-model="activeTab"
            :class="{ tabs_with_rtl: isRtl() }"
          >
            <!-- Personal Info Form  -->
            <el-tab-pane
              label="Personal Info"
              name="personalInfo"
              :class="{ 'text-right': isRtl() }"
            >
              <span slot="label"
                ><i class="el-icon-user"></i>
                <span>{{ localization("Personal Info") }}</span>
              </span>
              <el-form
                ref="personalInfoFormRef"
                :model="user"
                :rules="personalInfoRules"
                label-position="top"
                @submit.prevent="save"
                :class="{ 'text-right': isRtl() }"
              >
                <el-checkbox
                  v-model="user.public"
                  :class="{ check_box_with_rtl: isRtl() }"
                  >{{ localization("Public Profile") }}</el-checkbox
                >
                <el-row class="pt-2" :gutter="20">
                  <el-col :sm="24">
                    <el-row
                      class="pt-2"
                      :gutter="20"
                      :class="{ 'd-flex flex-row-reverse': isRtl() }"
                    >
                      <!-- First Name  -->
                      <el-col
                        :sm="12"
                        class="my-2"
                        :class="{ 'ues-right': isRtl() }"
                      >
                        <el-form-item
                          :label="`${localization('First Name')}`"
                          label-width="20%"
                          prop="first_name"
                        >
                          <el-input
                            :placeholder="`${localization('First Name')}`"
                            v-model="user.first_name"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- Last Name  -->
                      <el-col :sm="12" class="my-2">
                        <el-form-item
                          :label="`${localization('Last Name')}`"
                          label-width="20%"
                          prop="last_name"
                        >
                          <el-input
                            v-model="user.last_name"
                            class="purple-input"
                            :placeholder="`${localization('Last Name')}`"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- Email  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      :label="`${localization('Email')}`"
                      label-width="20%"
                      prop="email"
                    >
                      <el-input
                        style="width: 100%"
                        :placeholder="`${localization('Email')}`"
                        v-model="user.email"
                        class="purple-input"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- Age  -->
                  <el-col :sm="6" class="my-2">
                    <el-form-item
                      :label="`${localization('Age')}`"
                      label-width="20%"
                      prop="age_group_id"
                    >
                      <el-select
                        v-model="user.age_group_id"
                        :placeholder="`${localization('Age')}`"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in ageList"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- Language  -->
                  <el-col :sm="6" class="my-2">
                    <el-form-item
                      :label="`${localization('Language')}`"
                      label-width="20%"
                      prop="language"
                    >
                      <el-select
                        v-model="user.language"
                        :placeholder="`${localization('Language')}`"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in $store.getters['language/languages']"
                          :value="item.id"
                          :label="item.title"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- Postal Code  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      :label="`${localization('ZIP / Postal Code')}`"
                      label-width="20%"
                      prop="zip_code"
                    >
                      <el-input
                        v-model="user.zip_code"
                        :placeholder="`${localization('ZIP / Postal Code')}`"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- Country  -->
                  <el-col :sm="12" class="my-2">
                    <el-form-item
                      :label="`${localization('Country')}`"
                      label-width="20%"
                      prop="country"
                    >
                      <el-select
                        v-model="user.country"
                        filterable
                        :placeholder="`${localization('Country')}`"
                        class="select-default"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in countries"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col class="text-right">
                    <l-button @click="save" type="info" class="mr-0">
                      {{ localization("Change My Info") }}
                    </l-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>

            <!--//? Google Classroom Form  -->
            <el-tab-pane
              label="Google Class"
              name="googleClass"
              v-loading="classroomLoading"
            >
              <span slot="label"
                ><i class="el-icon-users"></i>
                {{ localization("Google Classroom") }}
              </span>

              <el-form
                ref="googleClassFormRef"
                :model="syncForm"
                :rules="googleClassRules"
                label-position="top"
              >
                <el-row class="pt-2" :gutter="20">
                  <!-- &lt;!&ndash; Email &ndash;&gt; -->
                  <!-- <div :span="24" class="tttttt"> -->
                  <el-col
                    :lg="24"
                    :class="{ 'text-right': auth().language == 2 }"
                  >
                    <el-form-item
                      :label="`${localization('Email')}`"
                      label-width="20%"
                      prop="email"
                    >
                      <el-input
                        style="width: 50%"
                        :placeholder="`${localization('Email')}`"
                        v-model="syncForm.email"
                        class="purple-input"
                        :disabled="this.user.student_classroom_id !== null"
                      />
                    </el-form-item>
                  </el-col>
                  <!-- </div> -->

                  <el-col class="text-right">
                    <l-button
                      v-if="!this.user.student_classroom_id"
                      @click="syncClass()"
                      type="info"
                      class="mr-0"
                    >
                      {{ localization("Sync Class") }}
                    </l-button>
                    <l-button
                      v-else
                      @click="unsyncClass()"
                      type="danger"
                      class="mr-0"
                    >
                      {{ localization("Unsync class") }}
                    </l-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>

            <!--//? Institution Info Form  -->
            <el-tab-pane
              name="institutionInfo"
              label="Institution Info"
              v-loading="isInstitutionInfoLoading"
              :class="{ 'text-right': auth().language == 2 }"
            >
              <span slot="label"
                ><i class="el-icon-office-building"></i>
                {{ localization("Institution Info") }}
              </span>
              <el-card>
                <template #header>
                  <div class="mb-2">{{ localization("My Institution") }}</div>
                </template>
                <div
                  v-if="user.organization_id != null"
                  :class="{ reverse_institution_wrapper: isRtl() }"
                >
                  <div class="d-flex mb-2" style="gap:8px">
                    <span class="d-block"
                      >{{ localization("Institution Name") }}:
                    </span>
                    <span class="d-block">{{ user.organization_name }}</span>
                  </div>
                  <div class="d-flex mb-2" style="gap:8px">
                    <span class="d-block"
                      >{{ localization("Institution Email") }}:</span
                    >
                    <span class="d-block">{{ user.organization_email }}</span>
                  </div>
                  <div class="d-flex mb-2" style="gap:8px">
                    <span class="d-block"
                      >{{ localization("Institution Contact") }}:</span
                    >
                    <span class="d-block">{{
                      user.organization_contact_name
                    }}</span>
                  </div>
                </div>
                <div v-else class="mb-3 text-gray">
                  {{ localization("Institution not found") }}
                </div>
                <el-col :span="24" class="text-right">
                  <l-button
                    v-if="!user.organization_id"
                    @click="show_institution_promo_dialog = true"
                    type="info"
                    class="mb-3"
                  >
                    {{ localization("Join Institution") }}
                  </l-button>

                  <l-button
                    v-if="user.organization_id"
                    @click="leaveOrganization"
                    type="danger"
                    class="mb-3"
                  >
                    {{ localization("Leave Institution") }}
                  </l-button>
                </el-col>
              </el-card>

              <!-- //? Join new Institution Form  -->
              <el-dialog
                :title="`${localization('Enter Institution Promo Code')}`"
                :visible.sync="show_institution_promo_dialog"
                width="60%"
                append-to-body
                class="add_strategy_dialog_task_user"
                :class="{ join_institution_with_rtl: isRtl() }"
              >
                <el-form
                  :model="promoForm"
                  v-loading="isInstitutionInfoLoading"
                  :rules="promoRules"
                  ref="promoRef"
                  @submit.prevent="submitPromoCode"
                >
                  <el-form-item
                    :label="`${localization('Promo Code')}`"
                    prop="new_institution_promo"
                  >
                    <el-input
                      v-model="promoForm.new_institution_promo.promo_code"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <span
                  slot="footer"
                  class="
                    dialog-footer
                    user_profile_dialog_footer
                  "
                >
                  <el-button
                    @click="show_institution_promo_dialog = false"
                    class="text-center text-capitalize"
                    >{{ localization("Cancel") }}</el-button
                  >
                  <el-button
                    type="primary"
                    @click="submitPromoCode"
                    class="text-center text-capitalize"
                    >{{ localization("Save") }}</el-button
                  >
                </span>
              </el-dialog>
            </el-tab-pane>

            <!-- //? Reset Password Form  -->
            <el-tab-pane
              name="resetPassword"
              label="Reset Password"
              v-loading="isResetPasswordLoading"
            >
              <span slot="label"
                ><i class="el-icon-lock"></i>
                {{ localization("Change Password") }}</span
              >
              <el-card>
                <template #header>
                  <div
                    class="mb-2"
                    :class="{ 'text-right': auth().language == 2 }"
                  >
                    {{ localization("Change User Password") }}
                  </div>
                </template>
                <el-form
                  v-model="resetPassword"
                  :model="resetPassword"
                  ref="resetPassRef"
                  :rules="resetPassRules"
                >
                  <div>
                    <el-row
                      :gutter="20"
                      :class="{ 'd-flex flex-row-reverse': isRtl() }"
                    >
                      <el-col :span="24" :lg="12">
                        <el-form-item
                          :label="`${localization('password')}`"
                          prop="password"
                          style="width: 100%"
                          class="position-relative"
                          :class="{ form_with_rtl_pass: isRtl() }"
                        >
                          <!-- Show Password Icon  -->
                          <i
                            v-if="passwordType == 'password'"
                            class="
                              fa fa-eye
                              position-absolute
                              togglePassword
                              text-secondary
                            "
                            @click="toggleShowPassword"
                            v-tooltip.top-center="
                              `${localization('Show Password')}`
                            "
                            aria-hidden="true"
                          ></i>
                          <i
                            v-if="passwordType == 'text'"
                            class="
                              fa fa-eye-slash
                              position-absolute
                              togglePassword
                              text-secondary
                            "
                            @click="toggleShowPassword"
                            v-tooltip.top-center="
                              `${localization('Hide Password')}`
                            "
                            aria-hidden="true"
                          ></i>
                          <el-input
                            :type="passwordType"
                            v-model="resetPassword.password"
                            :placeholder="
                              `${localization('Please enter Password')}`
                            "
                            style="width: 100%"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :lg="12">
                        <el-form-item
                          :label="`${localization('Confirm Password')}`"
                          prop="confirmPassword"
                          style="width: 100%"
                          class="position-relative"
                          :class="{ form_with_rtl_pass: isRtl() }"
                        >
                          <i
                            v-if="confirmPasswordType == 'password'"
                            class="
                              fa fa-eye
                              position-absolute
                              togglePassword
                              text-secondary
                            "
                            @click="toggleShowConfirmPassword"
                            v-tooltip.top-center="
                              `${localization('Show Password')}`
                            "
                            aria-hidden="true"
                          ></i>
                          <i
                            v-if="confirmPasswordType == 'text'"
                            class="
                              fa fa-eye-slash
                              position-absolute
                              togglePassword
                              text-secondary
                            "
                            @click="toggleShowConfirmPassword"
                            v-tooltip.top-center="
                              `${localization('Hide Password')}`
                            "
                            aria-hidden="true"
                          ></i>
                          <el-input
                            :type="confirmPasswordType"
                            v-model="resetPassword.confirmPassword"
                            :placeholder="
                              `${localization('Please enter Password')}`
                            "
                            style="width: 100%"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <div
                    style="width: 100%"
                    class="mb-3 mt-3 d-flex justify-content-end"
                  >
                    <l-button
                      @click="submit"
                      type="info"
                      class="pl-5 pr-5 d-block"
                    >
                      {{ localization("Submit") }}
                    </l-button>
                  </div>
                </el-form>
              </el-card>
            </el-tab-pane>

            <!--//? Billing Info Form  -->
            <!-- Only show this tab if user is a stundent or an inndividual -->
            <el-tab-pane
              name="billingInfo"
              label="Billing Info"
              v-if="this.showBillingInfo"
            >
              <span slot="label"
                ><i class="el-icon-money"></i>
                {{ localization("Billing Info") }}</span
              >
              <div class="p-1" :class="{ 'text-right': isRtl() }">
                {{ localization(this.user.type) }}
              </div>
              <el-alert
                show-icon
                class="mb-3 payment_alert"
                :class="{ is_rtl: isRtl() }"
              >
                {{ localization("By adding a credit card, you are subscribing to PersonalLearningCoach.com and will be billed annually.") }}
              </el-alert>
              <el-form
                ref="processPaymentFormRef"
                :model="credit"
                :rules="paymentRules"
                label-position="top"
              >
                <el-row :gutter="20">
                  <el-col :sm="24">
                    <el-row
                      class="pt-2"
                      :gutter="20"
                      :class="{ 'd-flex flex-row-reverse': isRtl() }"
                    >
                      <!-- Crad Name  -->
                      <el-col
                        :sm="12"
                        class="my-2"
                        :class="{ 'text-right': isRtl() }"
                      >
                        <el-form-item
                          :label="`${localization('Card Name')}`"
                          label-width="30%"
                          prop="card_name"
                        >
                          <el-input
                            v-model="credit.card_name"
                            class="purple-input"
                            :placeholder="`${localization('Card Name')}`"
                            :class="{ input_with_rtl: isRtl() }"
                          />
                        </el-form-item>
                      </el-col>
                      <!-- Card Number  -->
                      <el-col
                        :sm="12"
                        class="my-2"
                        :class="{ 'text-right': isRtl() }"
                      >
                        <el-form-item
                          :label="`${localization('Card Number')}`"
                          label-width="30%"
                          prop="card_number"
                        >
                          <el-input
                            v-model="credit.card_number"
                            class="purple-input"
                            :placeholder="`${localization('Card Number')}`"
                            maxlength="16"
                            :class="{ input_with_rtl: isRtl() }"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- :class="{ 'text-right': isRtl() }" -->
                  <el-col :sm="24">
                    <el-row
                      class="pt-2"
                      :gutter="20"
                      :class="{ 'd-flex flex-row-reverse': isRtl() }"
                    >
                      <!-- Expiration Date  -->
                      <el-col
                        :sm="12"
                        class="my-2"
                        :class="{ 'text-right': isRtl() }"
                      >
                        <el-form-item
                          :label="`${localization('Expiration Date')}`"
                          label-width="30%"
                          prop="exp_time"
                        >
                          <el-date-picker
                            v-model="credit.exp_time"
                            type="month"
                            :placeholder="`${localization('Expiration Date')}`"
                            format="MM/yy"
                            value-format="yyyy-MM"
                            :picker-options="datePickerOptions"
                            :class="{ input_with_rtl: isRtl() }"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <!-- CVC  -->
                      <el-col :sm="12" class="my-2">
                        <el-form-item
                          :label="`${localization('CVC')}`"
                          label-width="30%"
                          prop="cvc_code"
                        >
                          <el-input
                            v-model="credit.cvc_code"
                            class="purple-input"
                            :placeholder="`${localization('CVC')}`"
                            maxlength="3"
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>

                  <!-- Auto auto_renew check  -->
                  <el-col
                    :sm="12"
                    class="
                      d-flex
                      align-items-center
                      h-100
                      w-100
                    "
                    :class="{ 'flex-row-reverse column_with_rtl': isRtl() }"
                  >
                    <el-checkbox
                      v-model="credit.auto_renew"
                      :label="`${localization('Auto Renew')}`"
                      color="green"
                      :checked="autoRenewCheck"
                    ></el-checkbox>
                  </el-col>
                  <el-col :cols="24" class="text-right">
                    <l-button @click="processPayment" type="info" class="mr-0">
                      {{ localization("Process Payment") }}
                    </l-button>
                  </el-col>
                </el-row>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import Swal from "sweetalert2";
import { Auth } from "../../utils/auth";

export default {
  name: "edit",
  data() {
    return {
      menu: false,
      minDate: new Date(Date.now()).toISOString().substr(0, 10),
      isInstitutionInfoLoading: false,
      datePickerOptions: {
        disabledDate(date) {
          return date < new Date();
        }
      },
      personalInfoRules: {
        first_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        age_group_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        language: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      personalStatementsRules: {
        sequence_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        precision_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        technical_reasoning_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        confluence_statement: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      googleClassRules: {
        email: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      syncForm: { email: "" },
      dataSyncedWithClass: false,
      classroomLoading: false,
      paymentRules: {
        card_name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        card_number: [
          {
            min: 16,
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        exp_time: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        cvc_code: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur",
            min: 3
          }
        ]
      },
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password_confirmation: null,
        password: null,
        age_group_id: null,
        language: null,
        gender: null,
        type: null,
        state: null,
        country: null,
        city: null,
        student_classroom_id: null,
        zip_code: null,
        auto_renew: false,
        public: null
        // zip: null,
      },
      credit: {
        card_name: null,
        card_number: null,
        auto_renew: false,
        exp_time: null,
        cvc_code: null
      },
      email: {
        confirmemail: null
      },
      ageList: [
        { value: 1, label: "6-12 years old" },
        { value: 2, label: "13 -21 years old" },
        { value: 3, label: "22 or older" },
        { value: 4, label: "ABE" }
      ],

      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia ",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory ",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands ",
        "Central African Republic ",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands ",
        "Colombia",
        "Comoros ",
        "Congo ",
        "Congo ",
        "Cook Islands ",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic ",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands  [Malvinas]",
        "Faroe Islands ",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories ",
        "Gabon",
        "Gambia ",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands ",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands ",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger ",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands ",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands ",
        "United States of America ",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "Åland Islands"
      ],
      show_institution_promo_dialog: false,
      promoForm: {
        new_institution_promo: {
          promo_code: null
        }
      },
      promoRules: {
        new_institution_promo: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      joinFullORgError: "",
      isResetPasswordLoading: false,
      passwordType: "password",
      confirmPasswordType: "password",
      resetPassword: {},
      resetPassRules: {
        password: [
          {
            required: true,
            message: "Please input password",
            trigger: "blur"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please reenter password",
            trigger: "blur"
          }
        ]
      },
      // If user clicked Sync data from profile page: open edit profile with google classrooms tab active
      // else open personal Info tab
      activeTab:
        this.$route.path == "/sync-data" ? "googleClass" : "personalInfo"

      //  this.what_active == false ? "Statements"
    };
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    // auto auto_renew auto checked for US users
    autoRenewCheck() {
      return this.user.country === "United States";
    },
    showBillingInfo() {
      return (
        this.user.type != "Institution" &&
        this.user.type != "institution" &&
        !this.$route.params.id
      );
    },
    what_active() {
      return this.$store.getters["user/what_active"];
      // return activate_tab == false ? (this.activeTab = "Statements") : "";
    }
  },
  mounted() {
    this.$store.dispatch("user/user", this.id).then(_ => {
      this.user = this.$store.state.user.alluserData;
      this.org = this.$store.getters["user/orgs"];
      this.syncForm.email = this.user.student_classroom_id;
    });
    // this.activeTab = "Statements";

    // get languages available languages for user language set
    this.$store.dispatch("language/getLanguages");
    // set dataSyncedWithClass variable if users data is synced with A classroom
    this.dataSyncedWithClass = this.user.student_classroom_id ? true : false;
  },
  methods: {
    handleCredentialResponse(event) {
    },
    //// start google auth
    handleClickLogin() {
      const googleUser = this.$gAuth.signIn();
      this.$gAuth
        .getAuthCode()
        .then(authCode => {
          //on success
        })
        .catch(error => {
          //on fail do something
        });
    },
    async handleClickGetAuth() {
      try {
        // window.gapi.auth2.init({

        const authCode = await this.$gAuth.getAuthCode();
        // const response = await this.$http.post('http://your-backend-server.com/auth/google', { code: authCode, redirect_uri: 'postmessage' })
      } catch (error) {
        // On fail do something
      }
    },
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
      } catch (error) {
        // On fail do something
        console.error(error);
        return null;
      }
    },

    Refresh(query) {
      this.$store.dispatch("user/users", { query: query });
      this.$store.dispatch("topics/GetTopics", { query: null });
    },

    save() {
      this.$refs["personalInfoFormRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/save", { id: `/${this.id}`, query: this.user })
            .then(_ => {
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              // this.$store.dispatch("user/user", this.id);
              this.$router.go(-1);
            });
        } else {
          return false;
        }
      });
    },

    saveStatements() {
      this.$refs["personalStatementsRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/save", { id: `/${this.id}`, query: this.user })
            .then(_ => {
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              this.$router.go(-1);
            });
        } else {
          return false;
        }
      });
    },

    leaveOrganization() {
      // set laoding to true to shpw loading while making request
      this.isInstitutionInfoLoading = true;

      this.$store
        .dispatch("user/leaveOrganization", { id: this.id })
        .then(_ => {
          this.$store.dispatch("user/user", this.id).then(_ => {
            // refresh after leaving institution
            this.user = this.$store.state.user.alluserData;
            this.org = this.$store.getters["user/orgs"];
            // remove loading
            this.isInstitutionInfoLoading = false;
          });
        });
    },

    submitPromoCode() {
      this.$refs["promoRef"].validate(valid => {
        if (valid) {
          this.isInstitutionInfoLoading = true;
          this.$store
            .dispatch("user/joinOrganization", {
              promoCode: this.promoForm.new_institution_promo,
              id: this.id
            })
            .then(res => {
              this.joinFullORgError = this.$store.getters[
                "user/join_full_org_error"
              ];
              // if organization is full
              if (this.joinFullORgError.length > 0) {
                Swal.fire({
                  text: this.joinFullORgError,
                  text: `${this.localization(res.error_message)}`,
                  icon: "error",
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000
                }).then(() => {
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              } else {
                // if organization is not full
                // update data in panels

                this.$store.dispatch("user/user", this.user.id).then(() => {
                  // refresh after Joining institution
                  this.user = this.$store.state.user.alluserData;
                  this.org = this.$store.getters["user/orgs"];
                  // close promocode dialog
                  this.show_institution_promo_dialog = false;
                  // remove loading
                  this.isInstitutionInfoLoading = false;
                  // update table data
                  // this.Refresh({});
                  // empty promo code input
                  this.promoForm.new_institution_promo = {};
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    processPayment() {
      this.$refs["processPaymentFormRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/processPayment", {
              id: `${this.id}`,
              query: this.credit
            })
            .then(res => {
              if (!this.$route.params.id) {
                Auth(JSON.stringify(this.user));
              }
              this.$router.go(-1);
            });
        } else {
          return false;
        }
      });
    },
    // reste password methods
    toggleShowPassword() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
    toggleShowConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType == "password" ? "text" : "password";
    },
    // reset password
    submit() {
      this.$refs["resetPassRef"].validate(valid => {
        if (valid) {
          this.isResetPasswordLoading = true;
          //  If passwords does not match
          if (
            this.resetPassword.password !== this.resetPassword.confirmPassword
          ) {
            Swal.fire({
              icon: "error",
              text: `${this.localization("Password does not match")}`,
              confirmButtonText: `${this.localization("Ok")}`,
              confirmButtonClass: "btn btn-info ",
              showConfirmButton: true,
              showCloseButton: true,
              buttonsStyling: false
            }).then(data => {
              if (data.isConfirmed) {
                this.$store.dispatch("user/user", this.id);
                this.isResetPasswordLoading = false;
              }
            });
          } else {
            // call reset password on store
            this.$store
              .dispatch("user/changePasswordByAdmin", {
                id: this.user.id,
                newPasswordData: {
                  password: this.resetPassword.password,
                  confirm_password: this.resetPassword.confirmPassword
                }
              })
              .then(() => {
                Swal.fire({
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  confirmButtonClass: "btn btn-success",
                  showConfirmButton: false,
                  showCloseButton: true
                });
                this.isResetPasswordLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },

    // join class room
    syncClass() {
      this.classroomLoading = true;
      this.$refs["googleClassFormRef"].validate(valid => {
        if (valid) {
          this.$store
            .dispatch("user/syncClassRoom", {
              id: `${this.id}`,
              email: { email: `${this.syncForm.email}` }
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                showConfirmButton: false,
                showCloseButton: false,
                timer: 2000
              }).then(() => {
                this.$router.push("/profile");
                this.classroomLoading = false;
              });
            });
        } else {
          this.classroomLoading = false;
          return false;
        }
      });
    },
    unsyncClass() {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(data => {
        if (data.isConfirmed) {
          this.classroomLoading = true;
          this.$store
            .dispatch("user/unsyncClassRoom", {
              id: this.id
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false,
                showConfirmButton: true,
                confirmButtonClass: "btn btn-success ",
                showCloseButton: false,
                timer: 2000
              }).then(() => {
                this.$router.push("/profile");
                this.classroomLoading = false;
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.check_box_with_rtl {
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
  gap: 5px;
}
.container--fluid {
  min-height: 80vh !important;
}

.togglePassword {
  z-index: 5;
  right: 12px;
  bottom: 5%;
  transform: translateY(-50%);
  font-size: 1.1rem;
  cursor: pointer;
}
.user_profile_dialog_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}
.reverse_institution_wrapper {
  direction: rtl;
}
</style>

<style lang="scss">
.payment_alert {
  &.is_rtl {
    flex-direction: row-reverse;
    .el-alert__closebtn {
      left: 15px;
      right: auto;
    }
  }
}
.input_with_rtl {
  .el-input__inner {
    text-align: right;
  }
}
.column_with_rtl {
  .el-checkbox {
    display: flex;
    flex-direction: row-reverse;
    gap: 3px;
    margin-bottom: 20px;
  }
}
.add_strategy_dialog_task_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
// .tabs_with_rtl {
//   .el-tabs__header {
//     display: flex !important;
//     justify-content: end !important;
//   }
// }
.form_with_rtl_pass {
  .el-form-item__label {
    width: 100%;
    text-align: right;
  }
  .el-form-item__content {
    .togglePassword {
      left: 12px;
      right: auto;
    }
    .el-input__inner {
      text-align: right;
    }
  }
}

.join_institution_with_rtl {
  text-align: right;
  .el-form-item__label {
    width: 100%;
  }
}
.tabs_with_rtl {
  .el-tabs__nav-scroll {
    direction: rtl;
  }
  .el-tabs__nav {
    float: right;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__item {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      height: 0;
      background-color: #409eff;
      z-index: 1;
      width: 90%;
      bottom: 0;
      right: 0;
    }
    &.is-active {
      &::before {
        height: 2px;
      }
    }
  }
}
</style>
